<template>
  <div id="page-user-list">
    <!-- Knowledge Base Table -->
    <div class="vx-card p-6">
    
      <!-- Header Action -->
      <div class="flex justify-between flex-wrap items-center">
        <vs-input class="sm:mr-4 mr-0 sm:w-auto w-full sm:order-normal order-3 sm:mt-0 mt-4" v-model="searchQuery" @input="updateSearchQuery" placeholder="Search..." />

        <vs-button color="primary" icon-pack="feather" icon="icon-plus" class="mr-4" @click="$router.push({ name: 'learning-center-knowledge-base-create'})">Add Item</vs-button>
      </div>


      <!-- AgGrid Table Knowledge Base -->
      <ag-grid-vue
        ref="agGridTable"
        :components="components"
        :gridOptions="gridOptions"
        class="ag-theme-material w-100 my-4 ag-grid-table"
        :columnDefs="columnDefs"
        :defaultColDef="defaultColDef"
        :rowData="itemsData"
        rowSelection="multiple"
        colResizeDefault="shift"
        :animateRows="true"
        :floatingFilter="false"
        :pagination="true"
        :paginationPageSize="paginationPageSize"
        :suppressPaginationPanel="true"
        :enableRtl="$vs.rtl">
      </ag-grid-vue>

      <!-- Pagination Knowledge Base -->
      <div class="vx-row">
        <div class="vx-col sm:w-1/2 w-full">
          <!-- ITEMS PER PAGE -->
          <vs-dropdown vs-trigger-click class="cursor-pointer">
            <div class="p-4 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium">
              <span class="mr-2">{{ currentPage * paginationPageSize - (paginationPageSize - 1) }} - {{ itemsData.length - currentPage * paginationPageSize > 0 ? currentPage * paginationPageSize : itemsData.length }} of {{ itemsData.length }}</span>
              <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
            </div>
            <!-- <vs-button class="btn-drop" type="line" color="primary" icon-pack="feather" icon="icon-chevron-down"></vs-button> -->
            <vs-dropdown-menu>

              <vs-dropdown-item @click="gridApi.paginationSetPageSize(10)">
                <span>10</span>
              </vs-dropdown-item>
              <vs-dropdown-item @click="gridApi.paginationSetPageSize(20)">
                <span>20</span>
              </vs-dropdown-item>
              <vs-dropdown-item @click="gridApi.paginationSetPageSize(25)">
                <span>25</span>
              </vs-dropdown-item>
              <vs-dropdown-item @click="gridApi.paginationSetPageSize(30)">
                <span>30</span>
              </vs-dropdown-item>
            </vs-dropdown-menu>
          </vs-dropdown>
        </div>
        <div class="vx-col sm:w-1/2 w-full">
          <!-- PAGINATION -->
          <vs-pagination
            :total="totalPages"
            :max="7"
            v-model="currentPage" />
        </div>
      </div>
    </div>

    <!-- Popup Detail -->
    <vs-popup
      classContent="popup-example"
      title="Detail Learning Theory"
      :active.sync="popupDetail"
      v-if="item"
    >
      <!-- show detail -->
      <div class="grid grid-cols-2 justify-center">
        <div class="container mb-5">
          <h1 class="text-lg font-semibold mb-2">
            Created At
          </h1>
          <span class="block">
            {{ moment(item.created_at).format('DD-MM-YYYY') }}
          </span>
        </div>
        <div class="container mb-5">
          <h1 class="text-lg font-semibold mb-2">
            Updated At
          </h1>
          <span class="block">
            {{ moment(item.added_at).format('DD-MM-YYYY') }}
          </span>
        </div>
      </div>
    </vs-popup>

  </div>
  
</template>

<script>
import { AgGridVue } from 'ag-grid-vue'
import '@/assets/scss/vuexy/extraComponents/agGridStyleOverride.scss'
import vSelect from 'vue-select'
import moment from "moment";
import axios from '@/axios';

// Cell Renderer
import CellRendererActions from './cell-renderer/CellRendererActions.vue'

export default {
  components: {
    AgGridVue,
    vSelect,

    // Cell Renderer
    CellRendererActions,
  },
  data () {
    return {
      loaded: false,
      searchQuery: '',
      popup: false,
      popupDetail: false,
      item: {},
      moment: moment,

      // AgGrid
      gridApi: null,
      gridOptions: {},
      itemsData: [],
      defaultColDef: {
        sortable: true,
        resizable: true,
        suppressMenu: true
      },
      columnDefs: [
        {
          headerName: this.$i18n.t('Code'),
          field: 'code',
          filter: true,
          width: 160,
        },
        {
          headerName: this.$i18n.t('Title'),
          field: 'title',
          filter: true,
          width: 450,
          wrapText: true,
          autoHeight: true,
          cellStyle: {'line-height': 1.5}
        },
        {
          headerName: this.$i18n.t('Short Description'),
          field: 'summary',
          filter: true,
          width: 350,
          wrapText: true,
          autoHeight: true,
          hide: true,
          cellStyle: {'line-height': 1.5}
        },
        {
          headerName: this.$i18n.t('Created By'),
          field: 'creator.employee.name',
          filter: true,
          width: 250,
          wrapText: true,
          autoHeight: true,
          cellStyle: {'line-height': 1.5}
        },
        {
          headerName: 'Action',
          cellRendererFramework: 'CellRendererActions',
          cellRendererParams: {
            showItem: this.showItem.bind(this)
          },
          width: 200,
          headerClass: 'text-center',
          cellClass: 'text-center'
        }
      ],
    }
  },
  computed: {
    paginationPageSize () {
      if (this.gridApi) return this.gridApi.paginationGetPageSize()
      else return 10
    },
    totalPages () {
      if (this.gridApi) return this.gridApi.paginationGetTotalPages()
      else return 0
    },
    currentPage: {
      get () {
        if (this.gridApi) return this.gridApi.paginationGetCurrentPage() + 1
        else return 1
      },
      set (val) {
        this.gridApi.paginationGoToPage(val - 1)
      }
    }
  },
  methods: {
    updateSearchQuery (val) {
      this.gridApi.setQuickFilter(val)
    },

     loading () {
      this.loaded = false;

      this.$vs.loading({
        type: 'border',
        color: 'primary',
        text: `Wait a minute, It's getting data.`
      })

    },

    onLoaded () {
      this.$vs.loading.close();
      this.loaded = true;
    },

    getData () {
      axios.get(`/technical/learning-center/learning-theory/?categoryId=${this.id}`).then(resp => {
        this.onLoaded();
        this.itemsData = resp.data.data
      }
      ).catch(err => {
        this.$vs.notify({
          title:'Opps something error',
          text: err.data.message,
          color:'danger'
        })
      })
    },
    showItem (item) {
       axios.get('/technical/learning-center/learning-theory/' + item.id)
      .then(({data: res}) => {
        this.popupDetail = true
        this.item = res.data
      })
      .catch(err => console.error(err))
    },
  },
  mounted () {
    this.gridApi = this.gridOptions.api
    if (this.$vs.rtl) {
      const header = this.$refs.agGridTable.$el.querySelector('.ag-header-container')
      header.style.left = `-${  String(Number(header.style.transform.slice(11, -3)) + 9)  }px`
    }

    this.gridApi.sizeColumnsToFit()
  },
  watch: {
    $route () {
      this.getData();
    },
    popup () {
      if (!this.popup) {
        this.form = {};
      }
    }
  },
  created () {
    this.id = this.$secure.decrypt(this.$route.params.categoryId);
    this.loading();
    this.getData();
  }
}
</script>

<style lang="scss">
#page-user-list {
  .user-list-filters {
    .vs__actions {
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-58%);
    }
  }
  .ag-cell-wrap-text {
    word-break: break-word;
  }
}
</style>
