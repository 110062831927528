<template>
    <div :style="{'direction': $vs.rtl ? 'rtl' : 'ltr'}">
      <vs-button color="primary" size="small" icon-pack="feather" icon="icon-eye" style="float: left; margin-top: 10px;" @click="showRecord"></vs-button>
      <vs-button color="primary" size="small" style="padding: 0.7rem 1.5rem !important;" @click="showData">See Theory</vs-button>
    </div>
</template>

<script>
export default {
  name: 'CellRendererActions',
  computed: {
    encrypt () {
      let id = this.params.data.id;
      return this.$secure.encrypt(id);
    },
  },
  methods: {
    showRecord () {
      this.params.showItem(this.params.data)
    },
    showData () {
      this.$router.push({name: 'learning-center-knowledge-base-detail', params: { id: this.encrypt }});
    },
  }
}
</script>
